<template>
  <v-container fluid class="pt-0 align-start justify-start" fill-height>
    <v-dialog
        v-model="calculating"
        overlay-color="#121212"
        persistent
        max-width="500"
        style="box-shadow: none !important;"
        no-click-animation
        content-class="dialog-without-box-shadow"
    >
      <v-card tile style="background-color: transparent" elevation="0">
        <v-progress-circular
            indeterminate
            color="secondary"
            v-if="calculating"
            :size="100"
            :width="10"
            style="position: relative; left: 50%; transform: translateX(-50%);"
        />
        <v-subheader class="white--text subtitle-1 mt-2 text-uppercase d-flex justify-center">
          {{ calculation_text }}
        </v-subheader>
      </v-card>
    </v-dialog>
    <v-row style="flex: 1 0 100%;" class="py-0">
      <v-col cols="12" class="py-0">
        <v-card flat color="grey lighten-4">
          <v-card-text class="pa-0">
            <v-chip-group column>
              <v-chip label class="pa-5 white elevation-2">
                <v-icon left class="mr-3" color="error lighten-1" v-if="$vuetify.breakpoint.smAndUp">
                  mdi-account-supervisor-circle-outline
                </v-icon>
                {{ organization.short_name }}
              </v-chip>
              <v-chip label class="pa-5 white elevation-2">
                <v-icon left class="mr-3" color="purple lighten-1" v-if="$vuetify.breakpoint.smAndUp">mdi-clock-start
                </v-icon>
                <span class="text-capitalize">{{ current_month | formatDate('MMMM YYYY') }}</span>
              </v-chip>
              <v-chip label class="pa-5 white elevation-2" v-if="$vuetify.breakpoint.smAndUp">
                <v-icon left class="mr-3" color="blue lighten-1">mdi-account-outline</v-icon>
                {{ userName }}
              </v-chip>
              <v-chip label class="pa-5 white elevation-2" v-if="$vuetify.breakpoint.smAndUp" :to="'/instruction'">
                <v-icon left class="mr-3" color="error">mdi-youtube</v-icon>
                Інструкція
              </v-chip>
              <v-spacer></v-spacer>
              <v-chip label class="pa-5 mr-2 white elevation-2"
                      v-if="install_prompt"
                      @click="doInstallPrompt"
              >
                <v-icon size="30" color="success">mdi-cloud-download-outline</v-icon>
              </v-chip>
              <v-menu
                  :close-on-content-click="false"
                  offset-y
                  v-if="$vuetify.breakpoint.smAndUp"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip label class="pa-5 mr-0 white elevation-2"
                          v-bind="attrs"
                          v-on="on"
                  >
                    <img alt="#" class="mr-3" style="width: 30px; object-fit: contain;"
                         :src="`${publicPath}img/icons/logo_only1.png`">
                    WeToo | Віту. Про нас
                  </v-chip>
                </template>

                <v-card max-width="640">
                  <v-list class="py-0">
                    <v-list-item>
                      <v-list-item-avatar>
                        <img
                            :src="`${publicPath}img/icons/logo_only1.png`"
                            alt="WeToo | ВіТу"
                        >
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>
                          <a href="https://wetoo-online.vinksoft.com.ua"
                             class="grey--text text--darken-2"
                             target="_blank"
                             style="text-decoration: none">
                            WeToo | Віту
                          </a>
                        </v-list-item-title>
                        <v-list-item-subtitle>Платформа для обліку нарахувань</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>

                  <v-divider></v-divider>

                  <v-card-text>
                    <div style="display: flex">
                      <div style="flex: 0 0 140px">
                        Підтримка:
                      </div>
                      <div style="flex: 1">
                        <div><strong>Пн - Пт</strong> (09:00 - 19:00)</div>
                        <div>
                          <a class="success--text text--darken-1 text-decoration-none"
                             href="tel:+380965051535" target="_blank">+ 38 (096) 505-15-35</a></div>
                      </div>
                    </div>
                    <div style="display: flex; margin-top: 12px">
                      <div style="flex: 0 0 140px">
                        Побажання та пропозиції:
                      </div>
                      <div style="flex: 1">
                        <div>
                          <a class="success--text text--darken-1 text-decoration-none d-block"
                             href="mailto:support@vinksoft.com.ua" target="_blank">support@vinksoft.com.ua</a>
                          <a class="success--text text--darken-1 text-decoration-none d-block"
                             href="mailto:tovvinksoft@gmail.com" target="_blank">tovvinksoft@gmail.com</a>
                        </div>
                      </div>
                    </div>
                    <div style="display: flex; margin-top: 12px">
                      <div style="flex: 0 0 140px">
                        Бажаючим:
                      </div>
                      <div style="flex: 1">
                        <div><strong>Пн - Пт</strong> (09:00 - 19:00)</div>
                        <div>
                          <a class="success--text text--darken-1 text-decoration-none"
                             href="tel:+380967070047" target="_blank">+ 38 (096) 707-00-47</a></div>
                      </div>
                    </div>
                    <div style="display: flex; margin-top: 12px">
                      <div style="flex: 0 0 140px">
                        Сайт:
                      </div>
                      <div style="flex: 1">
                        <div>
                          <a
                              class="success--text text--darken-1 text-decoration-none"
                              href="https://wetoo-online.vinksoft.com.ua" target="_blank">Дізнатись детальнішу інформацію</a>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div style="display: flex; margin-top: 12px; margin-bottom: 12px">
                        <div style="flex: 0 0 140px">
                          Адреса:
                        </div>
                        <div style="flex: 1">
                          <div style="line-height: 1rem">м. Тернопіль, <br> вул. Митрополита Шептицького, будинок 20</div>
                        </div>
                      </div>
                      <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d647.1735619411824!2d25.59253280634904!3d49.546922521071465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473036b10f68bcc9%3A0x7882aa710bf647f0!2z0LLRg9C70LjRhtGPINCc0LjRgtGA0L7Qv9C-0LvQuNGC0LAg0KjQtdC_0YLQuNGG0YzQutC-0LPQviwgMjAsINCi0LXRgNC90L7Qv9GW0LvRjCwg0KLQtdGA0L3QvtC_0ZbQu9GM0YHRjNC60LAg0L7QsdC70LDRgdGC0YwsIDQ2MDAy!5e0!3m2!1suk!2sua!4v1656770602701!5m2!1suk!2sua"
                          width="400" height="200" style="border:0;" allowfullscreen="" loading="lazy"
                          referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                  </v-card-text>

                </v-card>
              </v-menu>
            </v-chip-group>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row style="flex: 1 0 100%; height: 50px;">
      <v-col cols="12" md="12" class="pt-0 pb-0">
        <v-card rounded style="border: none" elevation="2">
          <v-toolbar tile elevation="0" outlined dense style="border: none;">
            <v-toolbar-items class="shrink" v-if="$vuetify.breakpoint.smAndUp">
              <v-row class="pa- ma-0">
                <v-menu transition="slide-y-transition" bottom offset-y v-if="!organization.cabinet_only">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        text
                        tile
                        v-bind="attrs"
                        v-on="on"
                        min-width="100px"
                        height="100%"
                        small
                    >
                      <v-icon size="18" left color="teal" class="mr-2">mdi-book-open-outline</v-icon>
                      Операції
                    </v-btn>
                  </template>
                  <v-list class="subtitle-1" nav dense>
                    <v-menu
                        transition="slide-y-transition"
                        bottom
                        offset-y
                    >
                      <template v-slot:activator="{ on, attrs, value }">
                        <v-list-item v-on="on" v-bind="attrs" class="d-flex align-center">
                          <v-list-item-icon class="my-0" style="align-self: center">
                            <v-icon color="grey darken-1">mdi-calendar-arrow-right</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Закрити/Відкрити місяць</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon>
                              {{ value ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                            </v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                      <v-list class="subtitle-1" nav dense>
                        <v-list-item @click="changeMonthQuestion(true)">
                          <v-list-item-icon>
                            <v-icon color="success">mdi-calendar-arrow-right</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Перейти на {{ next_month | formatDate('MMMM YYYY') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="changeMonthQuestion(false)">
                          <v-list-item-icon>
                            <v-icon color="error">mdi-calendar-arrow-left</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Відкрити {{ prev_month | formatDate('MMMM YYYY') }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu
                        transition="slide-y-transition"
                        bottom
                        offset-y
                    >
                      <template v-slot:activator="{ on, attrs, value }">
                        <v-list-item v-on="on" v-bind="attrs" class="d-flex align-center">
                          <v-list-item-icon class="my-0" style="align-self: center">
                            <v-icon color="grey darken-1">mdi-file-export-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Експорт/Іморт</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon>
                              {{ value ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                            </v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                      <v-list class="subtitle-1" nav dense>
                        <v-list-item :to="{ name: 'EpsPutFile' }">
                          <v-list-item-icon>
                            <v-icon color="success">mdi-email-multiple-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Здати базу</v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item :to="{ name: 'PrivatPutFile' }">
                          <v-list-item-icon>
                            <v-icon color="secondary">mdi-email-multiple-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Здати базу в Privat24/ПриватБанк</v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item :to="{ name: 'ExportMeterReading' }">
                          <v-list-item-icon>
                            <v-icon color="purple">mdi-database-export</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Експорт показів (особистий кабінет)</v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item :to="{ name: 'EpsImportBase' }" v-if="super_user">
                          <v-list-item-icon>
                            <v-icon color="teal">mdi-database-import-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Імпорт абонентів із EPS</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu
                        transition="slide-y-transition"
                        bottom
                        offset-y
                        v-if="isPaysUser"
                    >
                      <template v-slot:activator="{ on, attrs, value }">
                        <v-list-item v-on="on" v-bind="attrs" class="d-flex align-center">
                          <v-list-item-icon class="my-0" style="align-self: center">
                            <v-icon color="grey darken-1">mdi-download-multiple</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Оплати</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon>
                              {{ value ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                            </v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                      <v-list class="subtitle-1" nav dense>
                        <v-list-item :to="{ name: 'RemoveDownloadedPayments' }">
                          <v-list-item-icon>
                            <v-icon color="error">mdi-bank-remove</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Вилучити завантажені оплати</v-list-item-title>
                        </v-list-item>
                        <v-list-item :to="{ name: 'PaymentImport' }">
                          <v-list-item-icon>
                            <v-icon color="secondary">mdi-account-cash-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Імпорт оплати</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu
                        transition="slide-y-transition"
                        bottom
                        offset-y
                    >
                      <template v-slot:activator="{ on, attrs, value }">
                        <v-list-item v-on="on" v-bind="attrs" class="d-flex align-center">
                          <v-list-item-icon class="my-0" style="align-self: center">
                            <v-icon color="grey darken-1">mdi-text-box-multiple-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Робота із заповненням</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon>
                              {{ value ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                            </v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                      <v-list class="subtitle-1" nav dense>
                        <v-list-item :to="{ name: 'ChangeBuildingTariff' }">
                          <v-list-item-icon>
                            <v-icon color="secondary">mdi-text-box-multiple-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Зміна тарифів по будинку</v-list-item-title>
                        </v-list-item>
                        <v-list-item :to="{ name: 'FillService' }">
                          <v-list-item-icon>
                            <v-icon color="teal">mdi-text-box-multiple-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Послуги по абонентах</v-list-item-title>
                        </v-list-item>
                        <v-list-item :to="{ name: 'FillTariffGroup' }">
                          <v-list-item-icon>
                            <v-icon color="teal">mdi-text-box-multiple-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Групи тарифів по абонентах</v-list-item-title>
                        </v-list-item>
                        <v-list-item :to="{ name: 'FillChecker' }">
                          <v-list-item-icon>
                            <v-icon color="teal">mdi-text-box-multiple-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Обліковці по абонентах</v-list-item-title>
                        </v-list-item>
                        <v-list-item :to="{ name: 'FillEntranceAndFloor' }">
                          <v-list-item-icon>
                            <v-icon color="teal">mdi-text-box-multiple-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Під’їзди та поверхи по абонентах</v-list-item-title>
                        </v-list-item>
                        <v-list-item :to="{ name: 'FillData' }">
                          <v-list-item-icon>
                            <v-icon color="teal">mdi-text-box-multiple-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Заповнення власників та площ</v-list-item-title>
                        </v-list-item>
                        <v-list-item :to="{ name: 'FillPostalIndex' }">
                          <v-list-item-icon>
                            <v-icon color="teal">mdi-mailbox-up-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Поштові індекси по абонетах</v-list-item-title>
                        </v-list-item>
                        <v-list-item :to="{ name: 'CreateFlats' }">
                          <v-list-item-icon>
                            <v-icon color="teal">mdi-text-box-multiple-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Створення квартир</v-list-item-title>
                        </v-list-item>
                        <v-list-item :to="{ name: 'ImportSocialQuery' }">
                          <v-list-item-icon>
                            <v-icon color="secondary">mdi-charity</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Пенсійний фонд (запит на тарифи)</v-list-item-title>
                        </v-list-item>
                        <v-list-item :to="{ name: 'CreatePensionTariffXmlFile' }">
                          <v-list-item-icon>
                            <v-icon color="secondary">mdi-charity</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Пенсійний фонд "Вітрина" (файл із тарифами)</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu
                        transition="slide-y-transition"
                        bottom
                        offset-y
                    >
                      <template v-slot:activator="{ on, attrs, value }">
                        <v-list-item v-on="on" v-bind="attrs" class="d-flex align-center">
                          <v-list-item-icon class="my-0" style="align-self: center">
                            <v-icon color="grey darken-1" size="26">mdi-message-text-fast-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Робота із смс</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon>
                              {{ value ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                            </v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                      <v-list class="subtitle-1" nav dense>
                        <v-list-item :to="{ name: 'SmsSendConfig' }">
                          <v-list-item-icon>
                            <v-icon color="teal">mdi-message-cog-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Налаштування смс сервісу</v-list-item-title>
                        </v-list-item>
                        <v-list-item :to="{ name: 'FillTableAndSendSms' }">
                          <v-list-item-icon>
                            <v-icon color="secondary">mdi-message-processing-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Розсилка смс повідомлень</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu
                        transition="slide-y-transition"
                        bottom
                        offset-y
                    >
                      <template v-slot:activator="{ on, attrs, value }">
                        <v-list-item v-on="on" v-bind="attrs" class="d-flex align-center">
                          <v-list-item-icon class="my-0" style="align-self: center">
                            <v-icon color="grey darken-1" size="26">mdi-bell-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Оголошення користувачам</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon>
                              {{ value ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                            </v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                      <v-list class="subtitle-1" nav dense>
                        <v-list-item :to="{ name: 'UserMessageHistory' }">
                          <v-list-item-icon>
                            <v-icon color="teal">mdi-bell-cog</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Налаштування оголошень</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list>
                </v-menu>
                <v-divider vertical class="grey lighten-3"/>
                <v-menu
                    transition="slide-y-transition"
                    bottom
                    offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        text
                        tile
                        v-bind="attrs"
                        v-on="on"
                        min-width="100px"
                        height="100%"
                        small
                    >
                      <v-icon small left color="purple lighten-1" class="mr-2">mdi-book-open-outline</v-icon>
                      Довідники
                    </v-btn>
                  </template>
                  <v-list class="subtitle-1" nav dense>
                    <v-list-item :to="{ name: 'GeneralSettings' }">
                      <v-list-item-icon>
                        <v-icon color="teal">mdi-card-bulleted-settings-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Загальні налаштування</v-list-item-title>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item :to="{ name: 'BalanceDetail' }">
                      <v-list-item-icon>
                        <v-icon color="primary">mdi-hand-coin-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Інформація про абонплату</v-list-item-title>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item :to="{ name: 'Organization' }">
                      <v-list-item-icon>
                        <v-icon color="primary lighten-1">mdi-layers-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Профіль організації</v-list-item-title>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item :to="{ name: 'SiteAdmin' }">
                      <v-list-item-icon>
                        <v-icon color="error lighten-1">mdi-layers-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Адмін-панель сайту</v-list-item-title>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item :to="{ name: 'UserEventLogger' }">
                      <v-list-item-icon>
                        <v-icon color="teal lighten-1">mdi-layers-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Журнал реєстрації дій</v-list-item-title>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item :to="{ name: 'PersonalCabinetDesktop' }">
                        <v-list-item-icon>
                            <v-icon color="warning darken-1">mdi-home-clock-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Запрошення в особистий кабінет</v-list-item-title>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item :to="{ name: 'UpdateInformation' }">
                      <v-list-item-icon>
                        <v-icon color="cyan darken-1">mdi-autorenew</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Опис оновлень платформи</v-list-item-title>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item :to="{ name: 'Streets', query: { current: 1 } }">
                      <v-list-item-icon>
                        <v-icon>mdi-city-variant-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Населені пункти</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'Streets', query: { current: 3 } }">
                      <v-list-item-icon>
                        <v-icon>mdi-city-variant-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Вулиці</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'Streets', query: { current: 4 } }">
                      <v-list-item-icon>
                        <v-icon>mdi-city-variant-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Будинки</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'Banks'}">
                      <v-list-item-icon>
                        <v-icon>mdi-city-variant-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Банки</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'Checkers'}">
                      <v-list-item-icon>
                        <v-icon>mdi-city-variant-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Обліковці</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'CounterTransferCoefficient'}">
                      <v-list-item-icon>
                        <v-icon>mdi-counter</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Коефіцієнти переведення</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'PenaltyTariff'}">
                      <v-list-item-icon>
                        <v-icon>mdi-circle-multiple-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Тариф пені</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'SpreedSheet'}">
                      <v-list-item-icon>
                        <v-icon>mdi-printer-pos-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Друковані форми</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-divider vertical class="grey lighten-3"/>
                <v-menu
                    transition="slide-y-transition"
                    bottom
                    offset-y
                    v-if="!organization.cabinet_only"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        text
                        tile
                        v-bind="attrs"
                        v-on="on"
                        min-width="100px"
                        height="100%"
                        small
                    >
                      <v-icon small left color="success darken-1" class="mr-2">mdi-book-open-outline</v-icon>
                      Внесення даних
                    </v-btn>
                  </template>
                  <v-list class="subtitle-1" nav dense>
                    <v-list-item :to="{ name: 'OddmentsDocuments'}">
                      <v-list-item-icon>
                        <v-icon>mdi-cash-100</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Внесення залишків</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item :to="{ name: 'FlatCashbackDocuments'}">
                      <v-list-item-icon>
                        <v-icon color="error lighten-1">mdi-label-percent-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Привілеювання (cashback)</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item :to="{ name: 'OneTimeCharge'}">
                      <v-list-item-icon>
                        <v-icon color="primary">mdi-animation-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Одноразове нарахування</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item :to="{ name: 'CloseSwapDebt'}">
                      <v-list-item-icon>
                        <v-icon color="orange darken-1">mdi-animation-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Закриття/перенесення боргу</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item :to="{ name: 'Removal'}">
                      <v-list-item-icon>
                        <v-icon color="teal">mdi-home-remove-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Зняття</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item :to="{ name: 'PaymentDocuments'}" v-if="isPaysUser">
                      <v-list-item-icon>
                        <v-icon color="success">mdi-cash-100</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Внесення оплат</v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="showCounters"></v-divider>
                    <v-list-item :to="{ name: 'ReadingDocument'}" v-if="showCounters">
                      <v-list-item-icon>
                        <v-icon color="primary">mdi-speedometer-medium</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Внесення показів</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item :to="{ name: 'Monetary'}">
                      <v-list-item-icon>
                        <v-icon color="success">mdi-bank</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Монетизація</v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="showCounters"></v-divider>
                    <v-list-item :to="{ name: 'FlatCounterAvgList'}" v-if="showCounters">
                      <v-list-item-icon>
                        <v-icon color="secondary">mdi-speedometer</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Покази по середньому</v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="showCounters"></v-divider>
                    <v-list-item :to="{ name: 'FlatCounterFmcList'}" v-if="showCounters">
                      <v-list-item-icon>
                        <v-icon color="teal">mdi-speedometer-slow</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Покази по повному споживанні</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item :to="{ name: 'DebtWorkCalls'}">
                      <v-list-item-icon>
                        <v-icon color="primary">mdi-phone-outgoing-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Дзвінки</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                  </v-list>
                </v-menu>
                <v-divider vertical class="grey lighten-3"/>
                <v-menu
                    transition="slide-y-transition"
                    bottom
                    offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        text
                        tile
                        v-bind="attrs"
                        v-on="on"
                        min-width="100px"
                        height="100%"
                        small
                        style="border-right: 1px solid #ececec"
                    >
                      <v-icon small left color="primary lighten-1" class="mr-2">mdi-book-open-outline</v-icon>
                      Звіти
                    </v-btn>
                  </template>
                  <v-list class="subtitle-1" nav dense>
                    <v-menu
                        transition="slide-y-transition"
                        bottom
                        offset-y
                        v-if="showCounters"
                    >
                      <template v-slot:activator="{ on, attrs, value }">
                        <v-list-item v-on="on" v-bind="attrs" class="d-flex align-center menu-item" dense>
                          <v-list-item-icon class="my-0" style="align-self: center">
                            <v-icon size="22" color="grey darken-1">mdi-speedometer</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Лічильники</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action class="my-0">
                            <v-icon>
                              {{ value ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                            </v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                      <v-list class="subtitle-1" nav dense>
                        <v-list-item class="menu-item"
                                     :to="{ name: 'Reports', params: { report_name: 'reading_statement' } }">
                          <v-list-item-icon>
                            <v-icon color="teal">mdi-speedometer</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Відомість на зняття показів</v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item class="menu-item"
                                     :to="{ name: 'Reports', params: { report_name: 'counter_count' } }">
                            <v-list-item-icon>
                                <v-icon color="teal">mdi-speedometer</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Кількість лічильників</v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item class="menu-item"
                                     :to="{ name: 'Reports', params: { report_name: 'counter_verification_end' } }">
                            <v-list-item-icon>
                                <v-icon color="teal">mdi-speedometer</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Відомість даних по повірці лічильників</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-divider class="menu-divider" v-if="showCounters"></v-divider>
                    <v-menu
                        transition="slide-y-transition"
                        bottom
                        offset-y
                    >
                      <template v-slot:activator="{ on, attrs, value }">
                        <v-list-item v-on="on" v-bind="attrs" class="d-flex align-center menu-item" dense>
                          <v-list-item-icon class="my-0" style="align-self: center">
                            <v-icon size="22" color="grey darken-1">mdi-file-document-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Договори / Претензії</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action class="my-0">
                            <v-icon>
                              {{ value ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                            </v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                      <v-list class="subtitle-1" nav dense>
                        <v-list-item class="menu-item"
                                     :to="{ name: 'Reports', params: { report_name: 'contract_stats' } }" dense>
                          <v-list-item-icon>
                            <v-icon color="teal">mdi-file-document-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Відомості по договорах</v-list-item-title>
                        </v-list-item>
                        <v-list-item :to="{ name: 'Reports', params: { report_name: 'indicator_check_contract_and_service_report_by_flat' } }">
                          <v-list-item-icon>
                            <v-icon color="teal">mdi-file-document-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Відомості розходж. виду показ. між дог. та посл.</v-list-item-title>
                        </v-list-item>
                        <v-list-item class="menu-item"
                                     :to="{ name: 'Reports', params: { report_name: 'restructuring_agreement_status' } }" dense>
                          <v-list-item-icon>
                            <v-icon color="teal">mdi-file-document-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Реструктуризація боргу</v-list-item-title>
                        </v-list-item>
                        <v-list-item class="menu-item"
                                     :to="{ name: 'Reports', params: { report_name: 'universal_pretense_report_by_flat' } }" dense>
                          <v-list-item-icon>
                            <v-icon color="secondary">mdi-home-alert-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Звіт по претензіях</v-list-item-title>
                        </v-list-item>
                        <v-list-item class="menu-item"
                                     :to="{ name: 'Reports', params: { report_name: 'universal_court_case_report_by_flat' } }" dense>
                          <v-list-item-icon>
                            <v-icon color="secondary">mdi-gavel</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Звіт по судовим справам</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-divider class="menu-divider" v-if="showPrivilege"></v-divider>
                    <v-menu
                        transition="slide-y-transition"
                        bottom
                        offset-y
                        v-if="showPrivilege"
                    >
                      <template v-slot:activator="{ on, attrs, value }">
                        <v-list-item v-on="on" v-bind="attrs" class="d-flex align-center menu-item" dense>
                          <v-list-item-icon class="my-0" style="align-self: center">
                            <v-icon size="22" color="grey darken-1">mdi-file-document-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Пільги</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action class="my-0">
                            <v-icon>
                              {{ value ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                            </v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                      <v-list class="subtitle-1" nav dense>
                        <v-list-item class="menu-item"
                                     :to="{ name: 'Reports', params: { report_name: 'privilege_report_blank_2' } }">
                          <v-list-item-icon>
                            <v-icon color="teal">mdi-file-document-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Відомості відшкодування пільги (Форма №2)</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-divider class="menu-divider"></v-divider>
                    <v-menu
                        transition="slide-y-transition"
                        bottom
                        offset-y
                        v-if="isPaysUser"
                    >
                      <template v-slot:activator="{ on, attrs, value }">
                        <v-list-item v-on="on" v-bind="attrs" class="d-flex align-center menu-item">
                          <v-list-item-icon class="my-0" style="align-self: center">
                            <v-icon size="22" color="grey darken-1">mdi-file-document-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Оплати</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action class="my-0">
                            <v-icon>
                              {{ value ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                            </v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                      <v-list class="subtitle-1" nav dense>
                        <v-list-item dense
                                     class="menu-item"
                                     :to="{ name: 'Reports', params: { report_name: 'pays_by_service_and_date_report_by_flat' } }">
                          <v-list-item-icon>
                            <v-icon color="grey darken-1">mdi-currency-usd</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Оплати в розрізі послуг</v-list-item-title>
                        </v-list-item>
                        <v-list-item class="menu-item"
                                     :to="{ name: 'Reports', params: { report_name: 'pays_by_date_report_by_flat' } }">
                          <v-list-item-icon>
                            <v-icon color="grey darken-1">mdi-currency-usd</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Реєстр оплат по днях/банках</v-list-item-title>
                        </v-list-item>
                        <v-list-item class="menu-item"
                                     :to="{ name: 'Reports', params: { report_name: 'pays_by_service_bank_and_date_general_report' } }">
                          <v-list-item-icon>
                            <v-icon color="grey darken-1">mdi-currency-usd</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Реєстр загальний по днях в розрізі банків</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-divider class="menu-divider"></v-divider>
                    <v-menu
                        transition="slide-y-transition"
                        bottom
                        offset-y
                    >
                      <template v-slot:activator="{ on, attrs, value }">
                        <v-list-item v-on="on" v-bind="attrs" class="d-flex align-center menu-item">
                          <v-list-item-icon class="my-0" style="align-self: center">
                            <v-icon size="22" color="grey darken-1">mdi-file-document-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Об’єми послуг/зняття/перерахунки та ін.</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action class="my-0">
                            <v-icon>
                              {{ value ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                            </v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                      <v-list class="subtitle-1" nav dense>
                          <v-list-item
                                  class="menu-item"
                                  :to="{ name: 'Reports', params: { report_name: 'statement_building_list_info' } }">
                              <v-list-item-icon>
                                  <v-icon size="22" color="red lighten-1">mdi-chart-box-outline</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>Відомість переліку будинків</v-list-item-title>
                          </v-list-item>
                        <v-list-item
                            class="menu-item"
                            :to="{ name: 'Reports', params: { report_name: 'recalculation_report_by_flat' } }">
                          <v-list-item-icon>
                            <v-icon size="22" color="red lighten-1">mdi-chart-box-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Відомість проведених перерахунків</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            class="menu-item"
                            :to="{ name: 'Reports', params: { report_name: 'removal_report_by_flat' } }">
                          <v-list-item-icon>
                            <v-icon size="22" color="red lighten-1">mdi-chart-box-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Відомість проведеного зняття</v-list-item-title>
                        </v-list-item>
                        <v-list-item class="menu-item"
                                     :to="{ name: 'Reports', params: { report_name: 'reading_balance' } }">
                          <v-list-item-icon>
                            <v-icon color="teal">mdi-file-document-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Баланс об’ємів послуг</v-list-item-title>
                        </v-list-item>
                        <v-list-item class="menu-item"
                                     :to="{ name: 'Reports', params: { report_name: 'provision_of_services' } }">
                            <v-list-item-icon>
                                <v-icon color="teal darken-1">mdi-file-document-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Забезпеченість послугами</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            class="menu-item"
                            :to="{ name: 'Reports', params: { report_name: 'flat_by_norm_and_counter_report' } }">
                          <v-list-item-icon>
                            <v-icon size="22" color="red lighten-1">mdi-chart-box-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Відомість об’ємів та нарахувань по нормі та лічильнику</v-list-item-title>
                        </v-list-item>
                        <v-list-item class="menu-item"
                                     :to="{ name: 'Reports', params: { report_name: 'person_hash_list_by_flat' } }">
                          <v-list-item-icon>
                            <v-icon size="22" color="teal darken-1">mdi-file-chart-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Список абонентів із кодами для особ.кабінету</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-divider class="menu-divider"></v-divider>
                    <v-list-item class="menu-item" :to="{ name: 'Reports', params: { report_name: 'general_report' } }">
                      <v-list-item-icon>
                        <v-icon size="22" color="success">mdi-finance</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Загальний звіт</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="menu-item" :to="{ name: 'Reports', params: { report_name: 'universal_general_report_by_grouping' } }">
                      <v-list-item-icon>
                        <v-icon size="22" color="secondary">mdi-finance</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Загальний звіт з групуванням</v-list-item-title>
                    </v-list-item>
                    <v-divider class="menu-divider"/>
                    <v-subheader style="height: 25px">Детальні звіти</v-subheader>
                    <v-list-item class="menu-item"
                                 :to="{ name: 'Reports', params: { report_name: 'universal_report_by_flat' } }">
                      <v-list-item-icon>
                        <v-icon size="22" color="secondary">mdi-file-chart-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Універсальний звіт по абонентах</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="menu-item"
                                 :to="{ name: 'Reports', params: { report_name: 'general_report_by_flat' } }">
                      <v-list-item-icon>
                        <v-icon size="22" color="teal darken-1">mdi-file-chart-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Оборотно-сальдова по будинках/квартирах</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="menu-item"
                                 :to="{ name: 'Reports', params: { report_name: 'general_report_by_flat_by_service' } }">
                      <v-list-item-icon>
                        <v-icon size="22" color="teal darken-1">mdi-file-chart-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Оборотно-сальдова по послугах та будинках/квартирах</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="menu-item"
                                 :to="{ name: 'Reports', params: { report_name: 'indicator_report_by_flat' } }">
                      <v-list-item-icon>
                        <v-icon size="22" color="red lighten-1">mdi-chart-box-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Відомість абонентів по якісно-кількісних показниках</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="menu-item"
                                 v-if="!itsBigOrganization"
                                 :to="{ name: 'Reports', params: { report_name: 'debt_group_report_by_flat' } }">
                      <v-list-item-icon>
                        <v-icon size="22" color="red lighten-1">mdi-account-cash</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Аналіз заборгованості</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="menu-item"
                                 :to="{ name: 'Reports', params: { report_name: 'debt_report_by_flat' } }">
                      <v-list-item-icon>
                        <v-icon size="22" color="red lighten-1">mdi-account-cash</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Звіт по боржниках</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="menu-item"
                                 :to="{ name: 'Reports', params: { report_name: 'universal_spreed_sheet_report_by_flat' } }">
                      <v-list-item-icon>
                        <v-icon size="22" color="red lighten-1">mdi-printer-pos-star-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Додаткові друковані форми</v-list-item-title>
                    </v-list-item>
                    <v-divider class="menu-divider"/>
                    <v-subheader style="height: 25px">Рахунки на оплату</v-subheader>
                    <v-list-item class="menu-item" :to="{ name: 'Reports', params: { report_name: 'receipt_simply' } }">
                      <v-list-item-icon>
                        <v-icon size="22" color="teal lighten-1">mdi-chart-box-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Спрощений рахунок</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="menu-item" :to="{ name: 'Reports', params: { report_name: 'receipt_detail' } }">
                      <v-list-item-icon>
                        <v-icon size="22" color="teal lighten-1">mdi-chart-box-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Детальний рахунок</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="menu-item" :to="{ name: 'Reports', params: { report_name: 'receipt_detail_with' } }">
                      <v-list-item-icon>
                        <v-icon size="22" color="teal lighten-1">mdi-chart-box-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Детальний рахунок (з корінцем)</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="menu-item"
                                 :to="{ name: 'Reports', params: { report_name: 'warning_standart' } }">
                      <v-list-item-icon>
                        <v-icon size="22" color="error lighten-1">mdi-chart-box-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Попередження про заборгованість</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'Reports', params: { report_name: 'debt_report_by_pages' } }">
                      <v-list-item-icon>
                        <v-icon color="error lighten-1">mdi-chart-box-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Друк боргів по під’їздах</v-list-item-title>
                    </v-list-item>
                    <v-divider class="menu-divider"/>
                  </v-list>
                </v-menu>
              </v-row>
            </v-toolbar-items>
            <v-toolbar-items class="shrink" v-else>
              <v-menu
                  transition="slide-y-transition"
                  bottom
                  offset-y
                  v-if="!organization.cabinet_only"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      text
                      tile
                      v-bind="attrs"
                      v-on="on"
                      min-width="100px"
                      height="100%"
                      small
                  >
                    <v-icon size="18" left color="teal" class="mr-2">mdi-book-open-outline</v-icon>
                    Операції
                  </v-btn>
                </template>
                <v-menu
                    transition="slide-y-transition"
                    bottom
                    offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item v-bind="attrs" v-on="on" dense class="white" style="width: 200px">
                      <v-list-item-icon>
                        <v-icon color="teal">mdi-book-open-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Операції</v-list-item-title>
                    </v-list-item>
                  </template>
                  <v-list class="subtitle-1" nav dense>
                    <v-menu
                        transition="slide-y-transition"
                        bottom
                        offset-y
                    >
                      <template v-slot:activator="{ on, attrs, value }">
                        <v-list-item v-on="on" v-bind="attrs" class="d-flex align-center">
                          <v-list-item-icon class="my-0" style="align-self: center">
                            <v-icon color="grey darken-1">mdi-calendar-arrow-right</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Закрити/Відкрити місяць</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon>
                              {{ value ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                            </v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                      <v-list class="subtitle-1" nav dense>
                        <v-list-item @click="changeMonthQuestion(true)">
                          <v-list-item-icon>
                            <v-icon color="success">mdi-calendar-arrow-right</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Перейти на {{ next_month | formatDate('MMMM YYYY') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="changeMonthQuestion(false)">
                          <v-list-item-icon>
                            <v-icon color="error">mdi-calendar-arrow-left</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Відкрити {{ prev_month | formatDate('MMMM YYYY') }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu
                        transition="slide-y-transition"
                        bottom
                        offset-y
                    >
                      <template v-slot:activator="{ on, attrs, value }">
                        <v-list-item v-on="on" v-bind="attrs" class="d-flex align-center">
                          <v-list-item-icon class="my-0" style="align-self: center">
                            <v-icon color="grey darken-1">mdi-file-export-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Експорт/Іморт</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon>
                              {{ value ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                            </v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                      <v-list class="subtitle-1" nav dense>
                        <v-list-item :to="{ name: 'EpsPutFile' }">
                          <v-list-item-icon>
                            <v-icon color="success">mdi-email-multiple-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Здати базу</v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item :to="{ name: 'PrivatPutFile' }">
                          <v-list-item-icon>
                            <v-icon color="secondary">mdi-email-multiple-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Здати базу в Privat24/ПриватБанк</v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item :to="{ name: 'ExportMeterReading' }">
                          <v-list-item-icon>
                            <v-icon color="purple">mdi-database-export</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Експорт показів (особистий кабінет)</v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item :to="{ name: 'EpsImportBase' }" v-if="super_user">
                          <v-list-item-icon>
                            <v-icon color="teal">mdi-database-import-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Імпорт абонентів із EPS</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu
                        transition="slide-y-transition"
                        bottom
                        offset-y
                        v-if="isPaysUser"
                    >
                      <template v-slot:activator="{ on, attrs, value }">
                        <v-list-item v-on="on" v-bind="attrs" class="d-flex align-center">
                          <v-list-item-icon class="my-0" style="align-self: center">
                            <v-icon color="grey darken-1">mdi-download-multiple</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Оплати</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon>
                              {{ value ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                            </v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                      <v-list class="subtitle-1" nav dense>
                        <v-list-item :to="{ name: 'RemoveDownloadedPayments' }">
                          <v-list-item-icon>
                            <v-icon color="error">mdi-bank-remove</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Вилучити завантажені оплати</v-list-item-title>
                        </v-list-item>
                        <v-list-item :to="{ name: 'PaymentImport' }">
                          <v-list-item-icon>
                            <v-icon color="secondary">mdi-account-cash-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Імпорт оплати</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu
                        transition="slide-y-transition"
                        bottom
                        offset-y
                    >
                      <template v-slot:activator="{ on, attrs, value }">
                        <v-list-item v-on="on" v-bind="attrs" class="d-flex align-center">
                          <v-list-item-icon class="my-0" style="align-self: center">
                            <v-icon color="grey darken-1">mdi-text-box-multiple-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Робота із заповненням</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon>
                              {{ value ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                            </v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                      <v-list class="subtitle-1" nav dense>
                        <v-list-item :to="{ name: 'ChangeBuildingTariff' }">
                          <v-list-item-icon>
                            <v-icon color="secondary">mdi-text-box-multiple-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Зміна тарифів по будинку</v-list-item-title>
                        </v-list-item>
                        <v-list-item :to="{ name: 'FillService' }">
                          <v-list-item-icon>
                            <v-icon color="teal">mdi-text-box-multiple-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Послуги по абонентах</v-list-item-title>
                        </v-list-item>
                        <v-list-item :to="{ name: 'FillTariffGroup' }">
                          <v-list-item-icon>
                            <v-icon color="teal">mdi-text-box-multiple-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Групи тарифів по абонентах</v-list-item-title>
                        </v-list-item>
                        <v-list-item :to="{ name: 'FillChecker' }">
                          <v-list-item-icon>
                            <v-icon color="teal">mdi-text-box-multiple-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Обліковці по абонентах</v-list-item-title>
                        </v-list-item>
                        <v-list-item :to="{ name: 'FillEntranceAndFloor' }">
                          <v-list-item-icon>
                            <v-icon color="teal">mdi-text-box-multiple-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Під’їзди та поверхи по абонентах</v-list-item-title>
                        </v-list-item>
                        <v-list-item :to="{ name: 'FillData' }">
                          <v-list-item-icon>
                            <v-icon color="teal">mdi-text-box-multiple-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Заповнення власників та площ</v-list-item-title>
                        </v-list-item>
                        <v-list-item :to="{ name: 'FillPostalIndex' }">
                          <v-list-item-icon>
                            <v-icon color="teal">mdi-mailbox-up-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Поштові індекси по абонентах</v-list-item-title>
                        </v-list-item>
                        <v-list-item :to="{ name: 'CreateFlats' }">
                          <v-list-item-icon>
                            <v-icon color="teal">mdi-text-box-multiple-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Створення квартир</v-list-item-title>
                        </v-list-item>
                        <v-list-item :to="{ name: 'ImportSocialQuery' }">
                          <v-list-item-icon>
                            <v-icon color="secondary">mdi-charity</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Пенсійний фонд (запит на тарифи)</v-list-item-title>
                        </v-list-item>
                        <v-list-item :to="{ name: 'CreatePensionTariffXmlFile' }">
                          <v-list-item-icon>
                            <v-icon color="secondary">mdi-charity</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Пенсійний фонд "Вітрина" (файл із тарифами)</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu
                        transition="slide-y-transition"
                        bottom
                        offset-y
                    >
                      <template v-slot:activator="{ on, attrs, value }">
                        <v-list-item v-on="on" v-bind="attrs" class="d-flex align-center">
                          <v-list-item-icon class="my-0" style="align-self: center">
                            <v-icon color="grey darken-1" size="26">mdi-message-text-fast-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Робота із смс</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon>
                              {{ value ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                            </v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                      <v-list class="subtitle-1" nav dense>
                        <v-list-item :to="{ name: 'SmsSendConfig' }">
                          <v-list-item-icon>
                            <v-icon color="teal">mdi-message-cog-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Налаштування смс сервісу</v-list-item-title>
                        </v-list-item>
                        <v-list-item :to="{ name: 'FillTableAndSendSms' }">
                          <v-list-item-icon>
                            <v-icon color="secondary">mdi-message-processing-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Розсилка смс повідомлень</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu
                        transition="slide-y-transition"
                        bottom
                        offset-y
                    >
                      <template v-slot:activator="{ on, attrs, value }">
                        <v-list-item v-on="on" v-bind="attrs" class="d-flex align-center">
                          <v-list-item-icon class="my-0" style="align-self: center">
                            <v-icon color="grey darken-1" size="26">mdi-bell-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Оголошення користувачам</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon>
                              {{ value ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                            </v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                      <v-list class="subtitle-1" nav dense>
                        <v-list-item :to="{ name: 'UserMessageHistory' }">
                          <v-list-item-icon>
                            <v-icon color="teal">mdi-bell-cog</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Налаштування оголошень</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list>
                </v-menu>
                <v-divider class="grey lighten-3"/>
                <v-menu
                    transition="slide-y-transition"
                    bottom
                    offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item v-bind="attrs" v-on="on" dense class="white" style="width: 200px">
                      <v-list-item-icon>
                        <v-icon color="purple lighten-1">mdi-book-open-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Довідники</v-list-item-title>
                    </v-list-item>
                  </template>
                  <v-list class="subtitle-1" nav dense>
                    <v-list-item :to="{ name: 'GeneralSettings' }">
                      <v-list-item-icon>
                        <v-icon color="teal">mdi-card-bulleted-settings-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Загальні налаштування</v-list-item-title>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item :to="{ name: 'BalanceDetail' }">
                      <v-list-item-icon>
                        <v-icon color="primary">mdi-hand-coin-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Інформація про абонплату</v-list-item-title>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item :to="{ name: 'Organization' }">
                      <v-list-item-icon>
                        <v-icon color="primary lighten-1">mdi-layers-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Профіль організації</v-list-item-title>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item :to="{ name: 'SiteAdmin' }">
                      <v-list-item-icon>
                        <v-icon color="error lighten-1">mdi-layers-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Адмін-панель сайту</v-list-item-title>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item :to="{ name: 'UserEventLogger' }">
                      <v-list-item-icon>
                        <v-icon color="teal lighten-1">mdi-layers-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Журнал реєстрації дій</v-list-item-title>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item :to="{ name: 'PersonalCabinetDesktop' }">
                        <v-list-item-icon>
                            <v-icon color="warning darken-1">mdi-home-clock-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Запрошення в особистий кабінет</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'UpdateInformation' }">
                      <v-list-item-icon>
                        <v-icon color="cyan darken-1">mdi-autorenew</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Опис оновлень платформи</v-list-item-title>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item :to="{ name: 'Streets', query: { current: 1 } }">
                      <v-list-item-icon>
                        <v-icon>mdi-city-variant-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Населені пункти</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'Streets', query: { current: 3 } }">
                      <v-list-item-icon>
                        <v-icon>mdi-city-variant-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Вулиці</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'Streets', query: { current: 4 } }">
                      <v-list-item-icon>
                        <v-icon>mdi-city-variant-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Будинки</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'Banks'}">
                      <v-list-item-icon>
                        <v-icon>mdi-city-variant-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Банки</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'Checkers'}">
                      <v-list-item-icon>
                        <v-icon>mdi-city-variant-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Обліковці</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'CounterTransferCoefficient'}">
                      <v-list-item-icon>
                        <v-icon>mdi-counter</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Коефіцієнти переведення</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'PenaltyTariff'}">
                      <v-list-item-icon>
                        <v-icon>mdi-circle-multiple-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Тариф пені</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'SpreedSheet'}">
                      <v-list-item-icon>
                        <v-icon>mdi-printer-pos-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Друковані форми</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-divider class="grey lighten-3"/>
                <v-menu
                    transition="slide-y-transition"
                    bottom
                    offset-y
                    v-if="!organization.cabinet_only"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item v-bind="attrs" v-on="on" dense class="white" style="width: 200px">
                      <v-list-item-icon>
                        <v-icon color="success darken-1">mdi-book-open-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Внесення даних</v-list-item-title>
                    </v-list-item>
                  </template>
                  <v-list class="subtitle-1" nav dense>
                    <v-list-item :to="{ name: 'OddmentsDocuments'}">
                      <v-list-item-icon>
                        <v-icon>mdi-cash-100</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Внесення залишків</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'FlatCashbackDocuments'}">
                      <v-list-item-icon>
                        <v-icon>mdi-label-percent-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Привілеювання (cashback)</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'OneTimeCharge'}">
                      <v-list-item-icon>
                        <v-icon color="primary">mdi-animation-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Одноразове нарахування</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'CloseSwapDebt'}">
                      <v-list-item-icon>
                        <v-icon color="orange darken-1">mdi-animation-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Закриття/перенесення боргу</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'Removal'}">
                      <v-list-item-icon>
                        <v-icon color="teal">mdi-home-remove-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Зняття</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'PaymentDocuments'}">
                      <v-list-item-icon>
                        <v-icon>mdi-cash-100</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Внесення оплат</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'ReadingDocument'}">
                      <v-list-item-icon>
                        <v-icon color="primary">mdi-speedometer-medium</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Внесення показів</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'Monetary'}">
                      <v-list-item-icon>
                        <v-icon color="success">mdi-bank</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Монетизація</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'FlatCounterAvgList'}" v-if="showCounters">
                      <v-list-item-icon>
                        <v-icon color="secondary">mdi-speedometer</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Покази по середньому</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'FlatCounterFmcList'}" v-if="showCounters">
                      <v-list-item-icon>
                        <v-icon color="teal">mdi-speedometer-slow</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Покази по повному споживанні</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'DebtWorkCalls'}">
                      <v-list-item-icon>
                        <v-icon color="primary">mdi-phone-outgoing-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Дзвінки</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-divider class="grey lighten-3"/>
                <v-menu
                    transition="slide-y-transition"
                    bottom
                    offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item v-bind="attrs" v-on="on" dense class="white" style="width: 200px">
                      <v-list-item-icon>
                        <v-icon color="primary lighten-1">mdi-book-open-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Звіти</v-list-item-title>
                    </v-list-item>
                  </template>
                  <v-list class="subtitle-1" nav dense>
                    <v-menu
                        transition="slide-y-transition"
                        bottom
                        offset-y
                        v-if="showCounters"
                    >
                      <template v-slot:activator="{ on, attrs, value }">
                        <v-list-item v-on="on" v-bind="attrs" class="d-flex align-center">
                          <v-list-item-icon class="my-0" style="align-self: center">
                            <v-icon color="grey darken-1">mdi-speedometer</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Лічильники</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon>
                              {{ value ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                            </v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                      <v-list class="subtitle-1" nav dense>
                        <v-list-item :to="{ name: 'Reports', params: { report_name: 'reading_statement' } }">
                          <v-list-item-icon>
                            <v-icon color="teal">mdi-speedometer</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Відомість на зняття показів</v-list-item-title>
                        </v-list-item>
                          <v-divider></v-divider>
                          <v-list-item class="menu-item"
                                       :to="{ name: 'Reports', params: { report_name: 'counter_count' } }">
                              <v-list-item-icon>
                                  <v-icon color="teal">mdi-speedometer</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>Кількість лічильників</v-list-item-title>
                          </v-list-item>
                          <v-divider></v-divider>
                          <v-list-item class="menu-item"
                                       :to="{ name: 'Reports', params: { report_name: 'counter_verification_end' } }">
                              <v-list-item-icon>
                                  <v-icon color="teal">mdi-speedometer</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>Відомість даних по повірці лічильників</v-list-item-title>
                          </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-divider></v-divider>
                    <v-menu
                        transition="slide-y-transition"
                        bottom
                        offset-y
                    >
                      <template v-slot:activator="{ on, attrs, value }">
                        <v-list-item v-on="on" v-bind="attrs" class="d-flex align-center">
                          <v-list-item-icon class="my-0" style="align-self: center">
                            <v-icon color="grey darken-1">mdi-file-document-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Договори / Претензії</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon>
                              {{ value ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                            </v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                      <v-list class="subtitle-1" nav dense>
                        <v-list-item :to="{ name: 'Reports', params: { report_name: 'contract_stats' } }">
                          <v-list-item-icon>
                            <v-icon color="teal">mdi-file-document-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Відомості по договорах</v-list-item-title>
                        </v-list-item>
                        <v-list-item :to="{ name: 'Reports', params: { report_name: 'indicator_check_contract_and_service_report_by_flat' } }">
                          <v-list-item-icon>
                            <v-icon color="teal">mdi-file-document-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Відомості розходж. виду показ. між дог. та посл.</v-list-item-title>
                        </v-list-item>
                        <v-list-item class="menu-item"
                                     :to="{ name: 'Reports', params: { report_name: 'restructuring_agreement_status' } }" dense>
                          <v-list-item-icon>
                            <v-icon color="teal">mdi-file-document-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Реструктуризація боргу</v-list-item-title>
                        </v-list-item>
                        <v-list-item class="menu-item"
                                     :to="{ name: 'Reports', params: { report_name: 'universal_pretense_report_by_flat' } }" dense>
                          <v-list-item-icon>
                            <v-icon color="secondary">mdi-home-alert-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Звіт по претензіях</v-list-item-title>
                        </v-list-item>
                        <v-list-item class="menu-item"
                                     :to="{ name: 'Reports', params: { report_name: 'universal_court_case_report_by_flat' } }" dense>
                          <v-list-item-icon>
                            <v-icon color="secondary">mdi-gavel</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Звіт по судовим справам</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-divider></v-divider>
                    <v-menu
                        transition="slide-y-transition"
                        bottom
                        offset-y
                        v-if="showPrivilege"
                    >
                      <template v-slot:activator="{ on, attrs, value }">
                        <v-list-item v-on="on" v-bind="attrs" class="d-flex align-center">
                          <v-list-item-icon class="my-0" style="align-self: center">
                            <v-icon color="grey darken-1">mdi-file-document-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Пільги</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon>
                              {{ value ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                            </v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                      <v-list class="subtitle-1" nav dense>
                        <v-list-item :to="{ name: 'Reports', params: { report_name: 'privilege_report_blank_2' } }">
                          <v-list-item-icon>
                            <v-icon color="teal">mdi-file-document-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Відомості відшкодування пільги (форма №2)</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-divider></v-divider>
                    <v-menu
                        transition="slide-y-transition"
                        bottom
                        offset-y
                        v-if="isPaysUser"
                    >
                      <template v-slot:activator="{ on, attrs, value }">
                        <v-list-item v-on="on" v-bind="attrs" class="d-flex align-center">
                          <v-list-item-icon class="my-0" style="align-self: center">
                            <v-icon color="grey darken-1">mdi-file-document-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Оплати</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon>
                              {{ value ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                            </v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                      <v-list class="subtitle-1" nav dense>
                        <v-list-item
                            :to="{ name: 'Reports', params: { report_name: 'pays_by_service_and_date_report_by_flat' } }">
                          <v-list-item-icon>
                            <v-icon color="grey darken-1">mdi-currency-usd</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Оплати в розрізі послуг</v-list-item-title>
                        </v-list-item>
                        <v-list-item :to="{ name: 'Reports', params: { report_name: 'pays_by_date_report_by_flat' } }">
                          <v-list-item-icon>
                            <v-icon color="grey darken-1">mdi-currency-usd</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Реєстр оплат по днях/банках</v-list-item-title>
                        </v-list-item>
                        <v-list-item class="menu-item"
                                     :to="{ name: 'Reports', params: { report_name: 'pays_by_service_bank_and_date_general_report' } }">
                          <v-list-item-icon>
                            <v-icon color="grey darken-1">mdi-currency-usd</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Реєстр загальний по днях в розрізі банків</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-divider></v-divider>
                    <v-menu
                        transition="slide-y-transition"
                        bottom
                        offset-y
                    >
                      <template v-slot:activator="{ on, attrs, value }">
                        <v-list-item v-on="on" v-bind="attrs" class="d-flex align-center" dense>
                          <v-list-item-icon class="my-0" style="align-self: center">
                            <v-icon color="grey darken-1">mdi-file-document-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Об’єми послуг/зняття/перерахунки та ін.</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon>
                              {{ value ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                            </v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                      <v-list class="subtitle-1" nav dense>
                        <v-list-item
                                class="menu-item"
                                :to="{ name: 'Reports', params: { report_name: 'statement_building_list_info' } }">
                            <v-list-item-icon>
                                <v-icon size="22" color="red lighten-1">mdi-chart-box-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Відомість переліку будинків</v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item
                            class="menu-item"
                            :to="{ name: 'Reports', params: { report_name: 'recalculation_report_by_flat' } }">
                          <v-list-item-icon>
                            <v-icon size="22" color="red lighten-1">mdi-chart-box-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Відомість проведених перерахунків</v-list-item-title>
                        </v-list-item>
                          <v-divider></v-divider>
                        <v-list-item
                            class="menu-item"
                            :to="{ name: 'Reports', params: { report_name: 'removal_report_by_flat' } }">
                          <v-list-item-icon>
                            <v-icon size="22" color="red lighten-1">mdi-chart-box-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Відомість проведеного зняття</v-list-item-title>
                        </v-list-item>
                          <v-divider></v-divider>
                        <v-list-item :to="{ name: 'Reports', params: { report_name: 'reading_balance' } }">
                          <v-list-item-icon>
                            <v-icon color="teal">mdi-file-document-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Баланс об’ємів послуг</v-list-item-title>
                            <v-divider></v-divider>
                        </v-list-item>
                          <v-list-item :to="{ name: 'Reports', params: { report_name: 'provision_of_services' } }">
                              <v-list-item-icon>
                                  <v-icon color="tea darken-1l">mdi-file-document-outline</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>Забезпеченысть послугами</v-list-item-title>
                          </v-list-item>
                          <v-divider></v-divider>
                        <v-list-item
                            :to="{ name: 'Reports', params: { report_name: 'flat_by_norm_and_counter_report' } }">
                          <v-list-item-icon>
                            <v-icon color="red lighten-1">mdi-chart-box-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Відомість об’ємів та нарахувань по нормі та лічильнику</v-list-item-title>
                        </v-list-item>
                        <v-list-item class="menu-item"
                                     :to="{ name: 'Reports', params: { report_name: 'person_hash_list_by_flat' } }">
                          <v-list-item-icon>
                            <v-icon size="22" color="teal darken-1">mdi-file-chart-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Список абонентів із кодами для особ.кабінету</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-divider></v-divider>
                    <v-subheader style="height: 25px">Загальні звіти</v-subheader>
                    <v-list-item :to="{ name: 'Reports', params: { report_name: 'general_report' } }">
                      <v-list-item-icon>
                        <v-icon color="success">mdi-finance</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Загальний звіт</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="menu-item" :to="{ name: 'Reports', params: { report_name: 'universal_general_report_by_grouping' } }">
                      <v-list-item-icon>
                        <v-icon size="22" color="secondary">mdi-finance</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Загальний звіт з групуванням</v-list-item-title>
                    </v-list-item>
                    <v-divider/>
                    <v-subheader style="height: 25px">Детальні звіти</v-subheader>
                    <v-list-item :to="{ name: 'Reports', params: { report_name: 'universal_report_by_flat' } }">
                      <v-list-item-icon>
                        <v-icon color="secondary">mdi-file-chart-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Універсальний звіт по абонентах</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'Reports', params: { report_name: 'general_report_by_flat' } }">
                      <v-list-item-icon>
                        <v-icon color="teal darken-1">mdi-file-chart-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Оборотно-сальдова по будинках/квартирах</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        :to="{ name: 'Reports', params: { report_name: 'general_report_by_flat_by_service' } }">
                      <v-list-item-icon>
                        <v-icon color="teal darken-1">mdi-file-chart-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Оборотно-сальдова по послугах та будинках/квартирах</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'Reports', params: { report_name: 'indicator_report_by_flat' } }">
                      <v-list-item-icon>
                        <v-icon color="red lighten-1">mdi-chart-box-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Відомість абонентів по якісно-кількісних показниках</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="!itsBigOrganization"
                        :to="{ name: 'Reports', params: { report_name: 'debt_group_report_by_flat' } }">
                      <v-list-item-icon>
                        <v-icon color="red lighten-1">mdi-account-cash</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Аналіз заборгованості</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'Reports', params: { report_name: 'debt_report_by_flat' } }">
                      <v-list-item-icon>
                        <v-icon color="red lighten-1">mdi-account-cash</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Звіт по боржниках</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'Reports', params: { report_name: 'universal_spreed_sheet_report_by_flat' } }">
                      <v-list-item-icon>
                        <v-icon size="22" color="red lighten-1">mdi-printer-pos-star-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Додаткові друковані форми</v-list-item-title>
                    </v-list-item>
                    <v-divider/>
                    <v-subheader style="height: 25px">Рахунки на оплату</v-subheader>
                    <v-list-item :to="{ name: 'Reports', params: { report_name: 'receipt_simply' } }">
                      <v-list-item-icon>
                        <v-icon color="teal lighten-1">mdi-chart-box-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Спрощений рахунок</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'Reports', params: { report_name: 'receipt_detail' } }">
                      <v-list-item-icon>
                        <v-icon color="teal lighten-1">mdi-chart-box-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Детальний рахунок</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="menu-item" :to="{ name: 'Reports', params: { report_name: 'receipt_detail_with' } }">
                      <v-list-item-icon>
                        <v-icon size="22" color="teal lighten-1">mdi-chart-box-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Детальний рахунок (з корінцем)</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'Reports', params: { report_name: 'warning_standart' } }">
                      <v-list-item-icon>
                        <v-icon color="error lighten-1">mdi-chart-box-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Попередження про заборгованість</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'Reports', params: { report_name: 'debt_report_by_pages' } }">
                      <v-list-item-icon>
                        <v-icon color="error lighten-1">mdi-chart-box-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Друк боргів по під’їздах</v-list-item-title>
                    </v-list-item>
                    <v-divider/>
                  </v-list>
                </v-menu>
              </v-menu>
            </v-toolbar-items>
            <v-spacer/>
            <v-btn fab small text class="grey lighten-3 mr-2"
                   :to="{ name: 'EpsPayments'}"
                   v-if="!simple_user && !organization.cabinet_only && isPaysUser"
            >
              <v-icon color="success">
                mdi-download-multiple
              </v-icon>
            </v-btn>
            <v-btn fab small text class="grey lighten-3 mr-2"
                   :to="{ name: 'BuildingCounter'}"
                   v-if="!organization.cabinet_only && showCounters"
            >
              <v-icon color="grey darken-2">
                mdi-gauge-low
              </v-icon>
            </v-btn>
            <v-btn fab small text class="grey lighten-3 mr-2"
                   :to="{ name: 'Reports', params: { report_name: 'general_report' } }"
            >
              <v-icon color="grey darken-2">
                mdi-chart-box-outline
              </v-icon>
            </v-btn>
            <v-btn fab small text class="success" v-if="!simple_user && !organization.cabinet_only">
              <v-icon dark @click="questionForCalcByAll">mdi-calculator</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-col>
    </v-row>
    <v-row style="flex: 1 1 100%; height: calc(100% - 106px)">
      <v-col cols="12" md="4" class="py-1">
        <v-card class="fill-height" style="display: flex; flex-direction: column; flex-wrap: nowrap">
          <v-card-title style="flex: 0">
            <v-row>
              <v-col cols="12" md="12" class="py-1 px-3">
                <div class="d-flex">
                  <v-btn class="mr-2" style="flex: 1; width: auto !important; max-width: auto !important;"
                         small depressed @click.stop="modal = true" :disabled="simple_user">
                    <v-icon left class="mr-4">mdi-plus</v-icon>
                    Новий абонент
                  </v-btn>
                  <YouTubeButton
                      button-class="grey lighten-4"
                      button-style="flex: 0 0 32px"
                      button-small
                      :button-icon="false"
                      icon-color="error lighten-1"
                      :icon-size="23"
                      href="https://youtu.be/BO3g2x09X9k"
                  />
                </div>
                <DesktopWorkPlaceCreateFlat v-model="modal" @chosenBuildingType="chosenBuildingType"/>

                <MainModal
                    :main="{ component: 'Flat', title: 'Квартира' }"
                    :button="{
                              icon: false,
                              buttonIconClass: 'mr-2',
                              buttonColor: 'grey lighten-4',
                              buttonIcon: 'mdi-plus',
                              buttonName: 'Новий абонент',
                              buttonSmall: true,
                              buttonOutlined: false,
                              buttonBlock: true,
                              buttonDepressed: true,
                              buttonHidden: true
                            }"
                    :max-width="880"
                    :item="{ buildingType: buildingTypeCreate }"
                    :modal="modalFlat"
                    v-if="modalFlat"
                    @updateItemModal="closeModalFlat"
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text style="flex: 1">
            <v-treeview
                dense
                activatable
                :items="tree"
                :open="open"
                :active.sync="treeItem"
                return-object
                color="grey darken-1"
                style="overflow: auto;"
                :style="getTreeStyle()"
            >
              <template v-slot:prepend="{ item }">
                <v-icon
                    class="mr-3"
                    size="25"
                    :color="getIconColor(item.type_)"
                    v-text="getIcon(item.type_)"
                />
              </template>
            </v-treeview>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" class="py-1">
        <v-card class="fill-height" style="display: flex; flex-direction: column">
          <v-card-title>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Пошук"
                color="grey darken-1"
                placeholder="Почніть вводити текст для пошуку"
                single-line
                hide-details
                class="px-4 mb-3"
                @input="doSearch"
            ></v-text-field>
          </v-card-title>
          <v-card-text style="flex: 1 1 100%">
            <v-data-table
                :headers="showOwnerBeforeAddress ? headersAlternative : headers"
                :items="flats"
                :items-per-page="15"
                no-data-text="Дані відсутні. Жодного запису не виявлено"
                :footer-props="{
                  showFirstLastPage: true,
                  itemsPerPageText: 'Рядків на сторінку',
                  itemsPerPageOptions: [5, 15, 30, -1]
                }"
                @click:row="openWorkPlace"
                class="custom-table"
            >
              <template v-slot:item.person_id="{ item }">
                <v-icon class="mr-2" color="error" size="22" v-if="item.disable">mdi-alert</v-icon>
                <v-icon class="mr-2" color="secondary" size="22" v-if="item.not_living">mdi-store</v-icon>
                <v-icon class="mr-2" color="grey darken-1" size="22" v-else>mdi-domain</v-icon>
                <span>{{ item.person_id }}</span>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MainModal from "@/components/modal/MainModal";
import {mapGetters, mapActions} from 'vuex'
import {FETCH_FLATS, SEARCH_FLAT_BY_TEXT} from "@/store/actions/flat";
import {QUESTION_SHOW, START_ANSWER_LOADING, STOP_ANSWER_LOADING} from "@/store/actions/question";
import {GET_TREE} from "@/store/actions/city";
import DesktopWorkPlaceCreateFlat from "@/components/modal/DesktopWorkPlaceCreateFlat";
import {OPEN_MONTH, CLOSE_MONTH} from "@/store/actions/work_month";
import {CALC_BY_ALL} from "@/store/actions/calculation";
import {SET_CURRENT_ORGANIZATION} from "@/store/actions/organization";
import {formatDate} from "@/filters";
import {VOTE_GET_STATS} from "@/store/actions/vote";
import {ALERT_SHOW} from "@/store/actions/alert";
import update from "@/mixins/update"
import {addMothsToStringDate, debounce} from "@/utils/icons";

const modalDeleteId = 'calc_by_all_modal'
const modalNextMonth = 'question_next_month'
const modalPrevMonth = 'question_prev_month'

export default {
  name: "HousePayDesktop",
  components: {
    MainModal,
    DesktopWorkPlaceCreateFlat,
    YouTubeButton: () => import("@/components/YouTubeButton")
  },
  mixins: [update],
  data() {
    return {
      data_loaded: false,
      calculating: false,
      treeItem: [],
      organization_loading: false,
      calculation_text: 'Зачекайте доки завершиться розрахунок...',
      open: [1],
      headers: [
        {text: 'Особовий рах.', value: 'person_id', width: 150},
        {text: 'Адреса', value: 'address'},
        {text: 'Власник', value: 'flat_owner_short_name'},
        {text: 'Площа', value: 'square', width: 120, align: 'right', sortable: false}
      ],
      headersAlternative: [
        {text: 'Особовий рах.', value: 'person_id', width: 150},
        {text: 'Власник', value: 'flat_owner_short_name'},
        {text: 'Адреса', value: 'address'},
        {text: 'Площа', value: 'square', width: 120, align: 'right', sortable: false}
      ],
      buildingTypeCreate: null,
      search: '',
      modal: false,
      modalFlat: false,
      data_loading: false,
      question_watcher: null,
      publicPath: process.env.BASE_URL,
      debounceFn: null,
    }
  },
  computed: {
    ...mapGetters({
      flats: 'getFlats',
      tree: 'getTree',
      organization: 'getCurrentOrganization',
      current_month: 'getCurrentMonth',
      prev_month: 'getPrevMonth',
      next_month: 'getNextMonth',
      modalAnswer: 'question_answer',
      userName: 'getUserName',
      userId: 'getUserId',
      simple_user: 'simple_user',
      accounting: 'isAccounting',
      desktopCondition: 'desktopCondition',
      legacy: 'isLegacy',
      settings: 'getGeneralSettings',
      super_user: 'isSuperUser',
      socket_data: 'get_socket',
      question_watcher_loading: 'question_watcher_loading',
      install_prompt: 'get_install_prompt',
      isPaysUser: 'isPaysUser'
    }),
    showPrivilege() {
      return this.settings?.work_place_privilege?.value
    },
    showCounters() {
      return this.settings?.work_place_counter?.value
    },
    itsBigOrganization() {
      return this.settings?.its_big_organization?.value
    },
    showOwnerBeforeAddress() {
      return this.settings?.desktop_owner_before_address?.value || false
    },
  },
  methods: {
    ...mapActions({
      fetchFlats: FETCH_FLATS,
      fetchTree: GET_TREE,
      searchFlat: SEARCH_FLAT_BY_TEXT,
      setOrganization: SET_CURRENT_ORGANIZATION,
    }),
    getTreeStyle() {
      if (this.$vuetify.breakpoint.xs) {
        return 'height: auto;'
      }
      if (this.$vuetify.breakpoint.sm) {
        return 'height: 40vh;'
      }
      return 'height: 80vh;'
    },
    async doInstallPrompt() {
      if (this.install_prompt !== null) {
        this.install_prompt.prompt();
        const { outcome } = await this.install_prompt.userChoice;
        if (outcome === 'accepted') {
          this.$store.commit('BEFORE_INSTALL_PROMPT', null)
        }
      }
    },
    changeMonthQuestion(next_month) {
      const title = next_month ? `Перейти на ${formatDate(this.next_month, 'MMMM YYYY')}` : `Відкрити ${formatDate(this.prev_month, 'MMMM YYYY')}`

      const payload = {
        text: title,
        accept_button: true,
        id: next_month ? modalNextMonth : modalPrevMonth
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    getIcon(type) {
      let icon = ''

      switch (type) {
        case "city":
          icon = 'mdi-city-variant';
          break;
        case 'street':
          icon = 'mdi-home-city-outline';
          break;
        case 'building':
          icon = 'mdi-office-building-outline';
          break;
        case 'entrance':
          icon = 'mdi-home-floor-a';
          break;
        case 'checker':
          icon = 'mdi-account-multiple-outline';
          break;
        case 'building_type':
          icon = 'mdi-bank-outline';
          break;
        case 'amalgamated_gromada':
          icon = 'mdi-map-legend'
          break;
        case 'city_area':
          icon = 'mdi-city-switch'
          break;
        default:
          icon = 'mdi-account'
      }

      return icon
    },
    getIconColor(type) {
      let icon = ''

      switch (type) {
        case "city":
          icon = 'primary lighten-1';
          break;
        case 'street':
          icon = 'success';
          break;
        case 'building':
          icon = 'secondary';
          break;
        case 'entrance':
          icon = 'error lighten-2';
          break;
        case 'checker':
          icon = 'teal';
          break;
        case 'building_type':
          icon = 'blue';
          break;
        case 'amalgamated_gromada':
          icon = 'teal'
          break;
        case 'city_area':
          icon = 'cyan'
          break;
        default:
          icon = 'error'
      }

      return icon
    },
    openWorkPlace(item) {
      this.$router.push(
          {name: 'WorkPlace', params: {person_hash: item.person_hash}}
      )
    },
    doSearch() {
      this.debounceFn()
    },
    searchFlatByText() {
      this.$nextTick(() => {
        const e = this.search

        if (e && e.length >= 2) {
          this.searchFlat(e)
        }

        if (!e) {
          if (this.treeItem.length) {
            const payload = {
              id: this.treeItem[0].obj_id,
              type: this.treeItem[0].type
            }
            this.fetchFlats(payload)
          }
        }
      })


    },
    chosenBuildingType(buildingType) {
      if (buildingType > 0) {
        if (buildingType === 2) {
          this.buildingTypeCreate = buildingType
        } else {
          this.buildingTypeCreate = null
        }
        this.modalFlat = true
      }
    },
    closeModalFlat(payload) {
      this.modalFlat = payload.modal
      this.buildingTypeCreate = null
    },
    set_work_month(close) {
      const c_month = close
          ? addMothsToStringDate(this.current_month, 1)
          : addMothsToStringDate(this.current_month, -1)

      this.calculating = true
      this.calculation_text = `Виконується операція переходу на ${formatDate(c_month, 'MMMM YYYY')} місяць...`

      if (close) {
        this.$store.dispatch(CLOSE_MONTH)
            .then((month) => {
              this.$ws.send_message(Object.assign({
                month: month,
                organization_id: this.organization.id,
                owner_id: this.userId
              }, {ws_type: 'month_change'}))
            })
            .finally(() => {
              this.calculating = false
              this.$store.dispatch(STOP_ANSWER_LOADING)
            })
      } else {
        this.$store.dispatch(OPEN_MONTH)
            .then((month) => {
              this.$ws.send_message(Object.assign({
                month: month,
                organization_id: this.organization.id,
                owner_id: this.userId
              }, {ws_type: 'month_change'}))
            })
            .finally(() => {
              this.calculating = false
              this.$store.dispatch(STOP_ANSWER_LOADING)
            })
      }
    },
    questionForCalcByAll() {
      const payload = {
        text: `Виконати розрахунок нарахувань,`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    watch_modal_answer() {
      this.question_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (this.question_watcher_loading) return

              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(START_ANSWER_LOADING)
                  this.calculating = true
                  this.calculation_text = 'Зачекайте доки завершиться розрахунок...'
                  this.$store.dispatch(CALC_BY_ALL)
                      .then(ok => {
                        if (ok) {
                          this.$store.commit(ALERT_SHOW, {text: 'Розрахунок завершено успішно', color: 'success'})
                        }
                      })
                      .finally(() => {
                        this.calculating = false
                        this.$store.dispatch(STOP_ANSWER_LOADING)
                      })
                }
              }

              if (payload.id === modalNextMonth) {
                if (payload.answer) {
                  this.$store.dispatch(START_ANSWER_LOADING)
                  this.set_work_month(true)
                }
              }

              if (payload.id === modalPrevMonth) {
                if (payload.answer) {
                  this.$store.dispatch(START_ANSWER_LOADING)
                  this.set_work_month(false)
                }
              }
            }
          }
      )
    },
  },
  beforeCreate() {
    // if (this.$store.getters.isAccounting) {
    //   this.$router.push({ name: 'AccountingDesktopView', params: { page_name: 'bank' } })
    // }
  },
  mounted() {
    // if (this.$store.getters.isAccounting) {
    //   this.$router.push({ name: 'AccountingDesktopView', params: { page_name: 'bank' } })
    // }
  },
  created() {
    this.debounceFn = debounce(() => this.searchFlatByText(), 800)
  },
  watch: {
    treeItem(value) {
      if (value.length) {
        const payload = {
          id: value[0].obj_id,
          type: value[0].type_
        }
        if (payload.type === 'entrance') {
          payload.add_id = value[0].building_id
        }
        if (this.data_loaded) {
          this.fetchFlats(payload)
        }
      }
    },
    desktopCondition: {
      immediate: true,
      handler(curr, prev) {
        if (JSON.stringify(curr) !== JSON.stringify(prev)) {
          if (curr.is_legacy) {
            this.$router.push({name: 'LegacyDesktop'})
          }
          if (curr.accounting) {
            this.$router.push({name: 'AccountingDesktopView', params: {page_name: 'bank'}})
          }
          if (!curr.accounting && !curr.is_legacy) {
            if (curr.dispatcher) {
              this.$router.push({name: 'DispatcherCall'})
            } else {
              this.watch_modal_answer()
              this.fetchTree()
                  .then(() => {
                    if (this.settings) {
                      this.headers[3].text = this.settings?.desktop_indicator_name?.value || 'Площа'
                      this.headersAlternative[3].text = this.settings?.desktop_indicator_name?.value || 'Площа'
                    }
                    const first = this.tree[0] || undefined
                    if (!first) {
                      this.treeItem = []
                    } else {
                      this.treeItem = [first]
                      const payload = {
                        id: first.obj_id,
                        type: first.type
                      }
                      this.fetchFlats(payload)
                          .then(() => this.data_loading = false)
                          .catch(() => this.data_loading = false)
                    }
                  })
                  .finally(() => this.data_loaded = true)
            }
          }
        }
      }
    },
    socket_data: {
      immediate: true,
      handler(payload) {
        if (payload) {
          if (payload.data.ws_type === 'vote_crud'
              && payload.data.organization_id === this.organization.id) {
            this.$store.dispatch(VOTE_GET_STATS)
          }
        }
      }
    }

  },
  beforeDestroy() {
    if (this.question_watcher) {
      this.question_watcher()
    }
  }

}
</script>

<style scoped lang="scss">
.menu-divider {
  border-color: rgba(0, 0, 0, 0.07) !important;
}

.menu-item {
  min-height: 34px !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;

  :deep(.v-list-item__title) {
    font-size: .795rem !important;
  }
  :deep(.v-list-item__icon) {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }
}

.custom-table {
  display: flex;
  flex-direction: column;
  height: 100%;

  :deep(.v-data-table__wrapper) {
    flex: 1 1 100%;
  }

  :deep(.v-data-table__wrapper) {
    th[role="columnheader"] {
      background-color: #fafafa;
      font-size: .8rem;
    }

    tbody {
      tr {
        td {
          height: 38px;
          cursor: pointer;
        }
      }
    }

    thead {
      tr {
        th {
          height: 38px;
        }
      }
    }
  }
}
</style>
